import gql from 'graphql-tag'

export const QUERY_GET_ERPS = gql`
  query {
    adminErps {
      _id
      name
      situation
      cnpj
      contacts {
        owner
        role
        email
        phone
      }
    }
  }
`
export const QUERY_GET_INTEGRATORS = gql`
  query {
    adminIntegrators {
      _id
      integrator
      description
      drivers {
        mysql {
          connection_string
          date_configuration
        }
        postgres {
          connection_string
          date_configuration
        }
        firebird {
          connection_string
          date_configuration
        }
        sqlserver {
          connection_string
          date_configuration
        }
        oracle {
          connection_string
          date_configuration
        }
      }
    }
  }
`
export const MUTATION_CREATE_ERP = gql`
  mutation($name: String!, $acronym: String!, $import_configuration: JSONObject!, $options: ErpInput) {
    newErp(name: $name, acronym: $acronym, import_configuration: $import_configuration, options: $options)
  }
`
export const MUTATION_UPDATE_ERP = gql`
  mutation($erp_id: String!, $input: ErpInput!) {
    updateErp(erp_id: $erp_id, input: $input) {
      message
      success
    }
  }
`
export const QUERY_GET_SINGLE_ERP = gql`
  query($id: ID!) {
    adminErp(id: $id) {
      _id
      name
      acronym
      import_configuration {
        sqls
        views
        commands
        output
        integrator
        driver
      }
      erp_index
      clients_index
      fantasy_name
      situation
      cnpj
      address {
        complement
        neighborhood
        number
        postal_code
        street
      }
      available_products
      contacts {
        owner
        role
        email
        phone
      }
    }
  }
`
