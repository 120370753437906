<template>
  <mf-loading-dialog :loading="$apollo.loading || loading">
    <base-page-layout :title="$route.meta.title">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <label class="card-title">CNPJ</label>
              <v-text-field v-model="erp.options.cnpj" v-mask="'##.###.###/####-##'" outlined :error-messages="errorMessages.cnpj" />
            </v-col>
            <v-col cols="6">
              <label class="card-title">Razão Social</label>
              <v-text-field v-model="erp.options.corporate_name" outlined :error-messages="errorMessages.corporate_name" />
            </v-col>
            <v-col cols="6">
              <label class="card-title">Nome Fantasia</label>
              <v-text-field v-model="erp.options.fantasy_name" outlined :error-messages="errorMessages.fantasy_name" />
            </v-col>
            <v-col cols="6">
              <label class="card-title">Acrônimo</label>
              <v-text-field v-model="formattedAcronym" disabled :rules="acronymRules" outlined :error-messages="errorMessages.fantasy_name" />
            </v-col>
            <v-col cols="6">
              <label class="card-title">Situação</label>
              <v-select v-model="erp.options.situation" label="-- Selecione -- " :items="situations" item-text="text" item-value="value" outlined />
            </v-col>
            <v-col cols="6">
              <label class="card-title">Produtos Disponíveis</label>
              <v-select
                v-model="erp.options.available_products"
                label="-- Selecione -- "
                :items="availableProductsItems"
                item-text="text"
                item-value="value"
                outlined
                multiple
              />
            </v-col>
          </v-row>

          <v-row class="mb-12">
            <v-col cols="12">
              <v-expansion-panels v-model="panel" class="mt-0" focusable>
                <!-- json preview -->
                <v-expansion-panel class="mt-3">
                  <v-expansion-panel-header>
                    <span class="card-title"><v-icon class="mb-2 mr-1">mdi-cog</v-icon> Configurações Avançadas</span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">
                          <label class="card-title">Integradores</label>
                          <v-select
                            v-model="erp.import_configuration.integrator"
                            label="-- Selecione -- "
                            :rules="integratorRules"
                            :items="integratorsList"
                            outlined
                          />
                          <v-btn :disabled="erp.import_configuration.integrator === ''" block color="primary" @click="openIntegratorConfig"
                            >CONFIGURAR INTEGRATOR</v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-expansion-panels v-model="panel2" class="mt-0" focusable>
                        <!-- json preview -->
                        <v-expansion-panel class="mt-3">
                          <v-expansion-panel-header>
                            <span class="card-title">JSON Preview</span>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-container fluid>
                              <vue-json-pretty :deep="1" :data.sync="formattedErp" highlight-mouseover-node show-line />
                            </v-container>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                      <v-alert elevation="4" class="mt-6" type="info" color="primary" outlined> Confirme os dados do ERP antes de salvar. </v-alert>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>

          <h2>Endereço:</h2>
          <v-row>
            <v-col cols="2">
              <label class="card-title">CEP</label>
              <v-text-field v-model="internalPostalCode" v-mask="'#####-###'" :loading="loadingCep" :error-messages="errorMessages.postal_code" outlined />
            </v-col>
            <v-col cols="2">
              <label class="card-title">Estado</label>
              <v-select
                v-model="erp.options.address.federative_unit"
                label="Estado"
                :items="estados"
                item-text="name"
                item-value="value"
                :error-messages="errorMessages.federative_unit"
                outlined
                single-line
              />
            </v-col>
            <v-col cols="2">
              <label class="card-title">Cidade</label>
              <v-select
                v-model="erp.options.address.city_name"
                label="Cidade"
                :disabled="erp.options.address.federative_unit === ''"
                :items="cidadesFiltradas()"
                item-text="Nome"
                item-value="Nome"
                :error-messages="errorMessages.city_name"
                outlined
                single-line
              />
            </v-col>
            <v-col cols="2">
              <label class="card-title">Bairro</label>
              <v-text-field v-model="erp.options.address.neighborhood" :error-messages="errorMessages.neighborhood" outlined />
            </v-col>
            <v-col cols="2">
              <label class="card-title">Rua</label>
              <v-text-field v-model="erp.options.address.street" :error-messages="errorMessages.street" outlined />
            </v-col>
            <v-col cols="2">
              <label class="card-title">Número</label>
              <v-text-field v-model="erp.options.address.number" :error-messages="errorMessages.number" outlined />
            </v-col>
            <v-col cols="12">
              <label class="card-title">Endereço Completo</label>
              <v-text-field v-model="fullAddress" :disabled="true" outlined />
            </v-col>
            <v-spacer />
          </v-row>

          <h2>Contatos:</h2>
          <contact-form :contacts.sync="erp.options.contacts" :validations="$v.erp.options" />
        </v-card-text>
      </v-card>

      <mf-action-buttons
        class="mt-2"
        :primary-button="{
          text: isCreate ? 'Criar ERP' : 'Editar ERP',
          action: isCreate ? create : saveErp,
          isVisible: true,
          isDisabled: false,
          isLoading: loadingSaveActive
        }"
        :cancel-button="{
          text: 'Cancelar',
          action: () => $router.push('/erps'),
          isVisible: true,
          isDisabled: false
        }"
      />
      <!-- modals -->
      <integrator-options
        :dialog="integratorConfigDialog"
        :import_configuration.sync="erp.import_configuration"
        :drivers="erp.import_configuration.integrator === 'database' || erp.import_configuration.integrator === 'refresh_view_database' ? drivers : []"
        :integrator_type="erp.import_configuration.integrator"
        @close="closeIntegratorConfig"
      />
    </base-page-layout>
  </mf-loading-dialog>
</template>

<script>
import _ from 'lodash'
import 'vue-json-pretty/lib/styles.css'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'

import { deepDelete } from '@/helpers/deepDelete'
import { getDataFromViaCep } from '@/helpers/cep'
import { data as cidades } from '@/assets/data/cidades.json'
import { data as estados } from '@/assets/data/estados.json'
import { validCnpj, notOnlySpace } from '@/helpers/validators.js'
import { MUTATION_UPDATE_ERP, MUTATION_CREATE_ERP, QUERY_GET_INTEGRATORS, QUERY_GET_SINGLE_ERP } from '@/modules/erps/graphql'

export default {
  components: {
    VueJsonPretty: () => import('vue-json-pretty'),
    ContactForm: () => import('@/components/form/ContactForm.vue'),
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    IntegratorOptions: () => import('@/modules/erps/views/components/IntegratorOptions.vue')
  },
  data: () => ({
    erp: {
      name: '',
      acronym: '',
      import_configuration: {
        commands: {},
        output: {
          file_path: '',
          file_extension: ''
        },
        views: {},
        sqls: {
          categorias_produtos: { query: '', atemporal: true },
          vendas_itens: { query: '', atemporal: true },
          clientes_contatos: { query: '', atemporal: true },
          clientes: { query: '', atemporal: true },
          categorias_clientes: { query: '', atemporal: true },
          lojas: { query: '', atemporal: true },
          vendas: { query: '', atemporal: true },
          clientes_enderecos: { query: '', atemporal: true },
          profissionais: { query: '', atemporal: true },
          produtos: { query: '', atemporal: true },
          categorias_profissionais: { query: '', atemporal: true },
          agenda_promocoes: { query: '', atemporal: true },
          clientes_temporarios: { query: '', atemporal: true }
        },
        integrator: '',
        drive: ''
      },
      options: {
        fantasy_name: '',
        contacts: [{}],
        address: {
          postal_code: '',
          federative_unit: '',
          city_name: '',
          neighborhood: '',
          street: '',
          complement: '',
          number: ''
        },
        situation: '',
        corporate_name: '',
        cnpj: '',
        available_products: []
      }
    },
    localAcronym: '',
    integratorsList: ['ssh', 'webservice_ciss', 'database', 'refresh_view_database', 'database_mongo_integrator', 'ftp', 'webservice_bluesoft'],
    integrators: [],
    acronymRules: [v => /^[a-z_0-9]*$/.test(v) || 'O acrônimo deve conter apenas letras, underline e números válida.', v => !!v || 'Acrônimo é obrigatório'],
    integratorRules: [v => !!v || 'Integrator é obrigatório'],
    loading: false,
    loadingSaveActive: false,
    cidades: cidades,
    estados: estados,
    loadingCep: false,
    panel: [],
    panel2: [],
    postalCodeTouched: false,
    integratorConfigDialog: false
  }),
  computed: {
    drivers: {
      get() {
        const dbIntegrator = this.integrators.filter(obj => obj.integrator === 'database')
        delete dbIntegrator[0].drivers.__typename
        return dbIntegrator && Object.keys(dbIntegrator[0].drivers)
      }
    },
    formattedAcronym: {
      set(value) {
        this.acronymTouched = true
        this.localAcronym = value
      },
      get() {
        if (this.acronymTouched) return this.localAcronym
        const parsedAcronym = _.deburr(
          this.erp.options.fantasy_name
            .trimEnd()
            .split(' ')
            .join('_')
            .toLowerCase()
        ).replace(/[^0-9a-z_]/g, '')

        return parsedAcronym.replace(/_+/g, '_')
      }
    },
    internalPostalCode: {
      get() {
        return this.erp.options.address.postal_code
      },
      set(value) {
        const newRaw = value.replace(/[^\d]+/g, '')
        const oldRaw = this.erp.options.address.postal_code.replace(/[^\d]+/g, '')
        if (newRaw !== oldRaw) {
          this.postalCodeTouched = true
        }
        this.erp.options.address.postal_code = value
      }
    },
    fullAddress: {
      get() {
        let address = {
          street: '',
          number: '',
          neighborhood: '',
          city_name: '',
          complement: '',
          federative_unit: ''
        }

        if (this.erp.options.address.street) address['street'] = this.erp.options.address.street + ', '
        if (this.erp.options.address.number) address['number'] = this.erp.options.address.number + ' - '
        if (this.erp.options.address.neighborhood) address['neighborhood'] = this.erp.options.address.neighborhood + ' - '
        if (this.erp.options.address.city_name) address['city_name'] = this.erp.options.address.city_name + ' - '
        if (this.erp.options.address.federative_unit) address['federative_unit'] = this.erp.options.address.federative_unit

        return `${address.street}${address.number}${address.neighborhood}${address.city_name}${address.federative_unit}`
      }
    },
    errorMessages() {
      const fields = ['cnpj', 'fantasy_name', 'corporate_name']

      const errors = fields.reduce((errors, field) => {
        if (this.$v.erp.options[field] && this.$v.erp.options[field].$error) {
          if (!this.$v.erp.options[field].required) errors[field] = 'Campo obrigatório.'
          else if (!this.$v.erp.options[field].notOnlySpace) errors[field] = 'Não é permitido salvar apenas espaços.'
        } else {
          errors[field] = ''
        }
        return errors
      }, {})
      if (this.$v.erp.options.address.postal_code.$error) errors['postal_code'] = 'CEP inválido.'
      if (this.$v.erp.options.address.federative_unit.$error) errors['federative_unit'] = 'Estado inválido.'
      if (this.$v.erp.options.address.city_name.$error) errors['city_name'] = 'Cidade inválida.'
      if (this.$v.erp.options.address.neighborhood.$error) errors['neighborhood'] = 'Bairro inválido.'
      if (this.$v.erp.options.address.street.$error) errors['street'] = 'Rua inválida.'
      if (this.$v.erp.options.address.number.$error) errors['number'] = 'Número inválido.'
      if (!errors.cnpj && !this.$v.erp.options.cnpj.validCnpj) errors.cnpj = 'CNPJ inválido.'
      return errors
    },
    situations() {
      return [
        {
          text: 'Integrado',
          value: 'integrated'
        },
        {
          text: 'Integrando',
          value: 'integrating'
        },
        {
          text: 'Pausado ou não integrado',
          value: 'not_integrated'
        }
      ]
    },
    availableProductsItems() {
      return [
        {
          text: 'Aplicativo',
          value: 'app'
        },
        {
          text: 'Voucher/Cashback',
          value: 'voucher_cashback'
        },
        {
          text: 'Oferta Especial',
          value: 'special_offer'
        },
        {
          text: 'Desconto em Porcentagem',
          value: 'percentage_discount'
        },
        {
          text: 'Pague e Leve e Combo',
          value: 'take_pay_combo'
        },
        {
          text: 'Oferta Personalizada',
          value: 'personalized_offer'
        },
        {
          text: 'Site',
          value: 'site'
        },
        {
          text: 'E-commerce',
          value: 'ecommerce'
        },
        {
          text: 'Performance por Operador',
          value: 'performance_per_operator'
        },
        {
          text: 'Mercapromo',
          value: 'merca_promo'
        }
      ]
    },
    isCreate() {
      return this.$route.meta.title === 'Criar ERP'
    },
    formattedErp: {
      get() {
        const erp = this.defineErpProperties()
        this.clearEmptyValues(erp)
        return erp
      }
    }
  },
  apollo: {
    adminIntegrators: {
      query: QUERY_GET_INTEGRATORS,
      update({ adminIntegrators }) {
        this.integrators = adminIntegrators
      }
    }
  },
  watch: {
    async 'erp.options.address.postal_code'(value) {
      await this.setAdress(value)
    }
  },
  mounted() {
    if (!this.isCreate) this.getErp()
  },
  methods: {
    async setAdress(value) {
      const raw = value.replace(/[^\d]+/g, '')
      if (raw.length !== 8 || !this.postalCodeTouched) {
        return
      }
      try {
        this.loadingCep = true
        const cepData = await getDataFromViaCep(raw)
        this.erp.options.address.federative_unit = cepData.uf || ''
        this.erp.options.address.city_name = cepData.localidade || ''
        this.erp.options.address.neighborhood = cepData.bairro || ''
        this.erp.options.address.street = cepData.logradouro || ''
      } catch (e) {
        this.$alert({
          alert_message: `Falha ao procurar o CEP`,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      this.loadingCep = false
    },
    closeIntegratorConfig() {
      this.integratorConfigDialog = false
    },
    openIntegratorConfig() {
      this.integratorConfigDialog = true
    },
    validateForm() {
      this.$v.$reset()
      this.$v.$touch()
      if (this.$v.$error) {
        this.$alert({
          alert_message: 'Preencha todos os campos corretamente',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
        this.$nextTick(() => this.$vuetify.goTo(document.querySelector('.error--text'), { offset: 200 }))
        return false
      }
      return true
    },
    clearEmptyValues(object) {
      _.forIn(object, (value, key) => {
        if (value === undefined || value === null || value === '') {
          delete object[key]
        } else if (typeof value === 'object') {
          this.clearEmptyValues(object[key])
        }
      })
    },
    defineErpProperties() {
      const objectToSave = _.cloneDeep(this.erp)
      switch (objectToSave.import_configuration.integrator) {
        case '':
          delete objectToSave.import_configuration
          break
        case 'rpinfo':
          delete objectToSave.import_configuration.drive
          delete objectToSave.import_configuration.commands
          delete objectToSave.import_configuration.output
          delete objectToSave.import_configuration.sqls
          delete objectToSave.import_configuration.views
          break
        case 'ssh':
          delete objectToSave.import_configuration.sqls
          delete objectToSave.import_configuration.drive
          delete objectToSave.import_configuration.views
          break
        case 'webservice_ciss':
          delete objectToSave.import_configuration.drive
          delete objectToSave.import_configuration.commands
          delete objectToSave.import_configuration.output
          delete objectToSave.import_configuration.views
          break
        case 'database':
          delete objectToSave.import_configuration.commands
          delete objectToSave.import_configuration.output
          delete objectToSave.import_configuration.views
          break
        case 'refresh_view_database':
          delete objectToSave.import_configuration.commands
          delete objectToSave.import_configuration.output
          break
        case 'database_mongo_integrator':
          delete objectToSave.import_configuration.commands
          delete objectToSave.import_configuration.output
          objectToSave.import_configuration.sqls = {}
          break
        case 'webservice_bluesoft':
          delete objectToSave.import_configuration.commands
          delete objectToSave.import_configuration.output
          delete objectToSave.import_configuration.views
          break
        case 'ftp':
          delete objectToSave.import_configuration.sqls
          delete objectToSave.import_configuration.drive
          delete objectToSave.import_configuration.views
          delete objectToSave.import_configuration.commands
          delete objectToSave.import_configuration.output
          break
        default:
          objectToSave.import_configuration = []
      }

      objectToSave.acronym = this.formattedAcronym
      return objectToSave
    },
    formatForm() {
      const payload = {
        ...this.erp,
        name: this.erp.options.fantasy_name,
        acronym: this.formattedAcronym,
        options: {
          ...this.erp.options,
          address: {
            ...this.erp.options.address,
            number: parseInt(this.erp.options.address.number),
            full_address: this.fullAddress
          },
          contacts: this.erp.options.contacts,
          cnpj: this.erp.options.cnpj.replace(/[^0-9]/g, '')
        }
      }

      deepDelete(payload, ['corporate_name'])

      return payload
    },
    cidadesFiltradas() {
      return this.cidades.filter(cidade => cidade.Uf === this.erp.options.address.federative_unit)
    },
    async getErp() {
      try {
        this.loading = true
        const {
          data: { adminErp }
        } = await this.$apollo.query({
          query: QUERY_GET_SINGLE_ERP,
          fetchPolicy: 'no-cache',
          variables: {
            id: this.$route.params.id
          }
        })
        this.erp = { ...adminErp, ...this.erp }
        this.erp.name = adminErp?.name || ''
        this.erp.options.corporate_name = adminErp?.name || ''
        this.erp.acronym = adminErp?.acronym || ''
        this.erp.options.cnpj = adminErp?.cnpj || ''
        this.erp.options.situation = adminErp?.situation || ''
        this.erp.options.contacts = adminErp?.contacts || []
        this.erp.options.fantasy_name = adminErp?.fantasy_name || ''
        this.erp.options.available_products = adminErp?.available_products || ''
        this.erp.import_configuration = adminErp?.import_configuration[0]
        this.erp.options.address = adminErp?.address || {}
        this.postalCodeTouched = true
        if (adminErp?.address?.postal_code) await this.setAdress(adminErp?.address?.postal_code)
      } catch (error) {
        console.log(error)
        this.loading = false
        this.$alert({
          alert_message: `Dados do banco estão incorretos`,
          alert_title: 'Aviso!',
          alert_color: 'warning',
          alert_icon: 'mdi-close-circle'
        })
      } finally {
        this.loading = false
      }
    },
    async saveErp() {
      const isValidated = this.validateForm()
      if (!isValidated) return

      delete this.erp.address['__typename']
      this.erp.options.contacts = this.erp.options.contacts.map(contact => {
        delete contact['__typename']
        return contact
      })

      try {
        this.loadingSaveActive = true
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_ERP,
          variables: {
            input: {
              address: {
                ...this.erp.address,
                full_address: this.fullAddress
              },
              available_products: this.erp.options.available_products,
              clients_index: this.erp.clients_index,
              cnpj: this.erp.cnpj,
              contacts: this.erp.options.contacts,
              erp_index: this.erp.erp_index,
              fantasy_name: this.erp.fantasy_name,
              situation: this.erp.situation.toLocaleLowerCase()
            },
            erp_id: this.$route.params.id
          },
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            },
            uri: this.$microservicesUrls['crm']
          }
        })
        setTimeout(() => {
          this.$alert({
            alert_message: `ERP editado com sucesso`,
            alert_title: 'Sucesso!',
            alert_color: 'success',
            alert_icon: 'mdi-check-circle'
          })
          this.$router.push(`/erps`)
        }, 300)
      } catch (error) {
        this.$alert({
          alert_message: `Falha ao editar ERP`,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      } finally {
        this.loadingSaveActive = false
      }
    },
    async create() {
      const isValidated = this.validateForm()
      if (!isValidated) return

      const payload = this.formatForm()

      this.loadingSaveActive = true

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_CREATE_ERP,
          variables: {
            ...payload
          }
        })

        this.loadingSaveActive = false

        setTimeout(() => {
          this.$alert({
            alert_message: 'ERP criado com sucesso',
            alert_title: 'Sucesso!',
            alert_color: 'success',
            alert_icon: 'mdi-check-circle'
          })
          this.$router.push(`/erps`)
        }, 300)
      } catch (e) {
        this.$alert({
          alert_message: `Falha ao criar ERP`,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      this.loadingSaveActive = false
    }
  },
  validations: {
    erp: {
      options: {
        cnpj: { required, validCnpj },
        fantasy_name: { required, notOnlySpace },
        corporate_name: { required, notOnlySpace },
        address: {
          postal_code: { required, notOnlySpace, minLength: minLength(9), maxLength: maxLength(9) },
          federative_unit: { required, notOnlySpace },
          city_name: { required, notOnlySpace },
          neighborhood: { required, notOnlySpace },
          street: { required, notOnlySpace },
          number: { required }
        },
        contacts: {
          required,
          $each: {
            owner: { required },
            role: { required },
            email: { required, email },
            phone: { required, notOnlySpace, minLength: minLength(18), maxLength: maxLength(19) }
          }
        }
      }
    }
  }
}
</script>
